.gov__text-input {
    height: 50px;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.03px;
    font-weight: $fontWeightExtraBold;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $grey;
    box-sizing: border-box;
    transition: border-bottom ease 0.5s;
  
    &::placeholder {
        color: $greyLight;
    }
  
    &:focus {
        outline: 0;
        border-bottom: 3px solid $greenPrimary;
    }

    &.error {
        border-bottom: 3px solid $red;
    }
}

@media screen and (max-width: 767px) {
    .gov__text-input {
        // BUGFIX - Stops a curve on the input field on iOS
        border-radius: 1px;
    }
}
