.gov__date-of-arrival {
  .gov__date-of-arrival-question {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .gov__date-of-arrival-buttons {
    .radio {
      margin: 0.5rem;
      margin-bottom: 1rem;

      input[type="radio"] {
        position: absolute;
        opacity: 0;

        + .radio-label {
          &:before {
            float: left;
            content: '';
            background: $white;
            border-radius: 100%;
            border: 0.3px solid darken($white, 25%);
            display: inline-block;
            width: 1.8em;
            height: 1.8em;
            top: -0.3em;
            margin-right: 1em;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }

        &:checked {
          + .radio-label {
            &:before {
              background-color: $greenPrimary;
              box-shadow: inset 0 0 0 4px $white;
            }
          }
        }

        &:focus {
          + .radio-label {
            &:before {
              outline: none;
              border-color: $greenPrimary;
            }
          }
        }

        + .radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
.gov__form-group {
  margin-top: 30px;
}