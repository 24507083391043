#modal-dialog {
  background: transparent;
  border: none;
  padding: 0;

  /* width: 100vw;
  height: 100dvh;
  margin: 0;
  display: flex;
  flex-direction: column; */
}
