@keyframes fadeIn {
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    100% {
        opacity: 1;
    }
}

@keyframes slideInUp {
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideInUp {
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-moz-keyframes slideInUp {
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes slideInDown {
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideInDown {
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-moz-keyframes slideInDown {
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
