.gov__document-upload-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gov__document-upload-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gov__document-upload-title {
  text-align: center;
}

.gov__document-upload-central-container {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.onfido-sdk-ui-Theme-root .ods-button.-action--primary {
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s;
  height: 72px;
}

.onfido-sdk-ui-Theme-icon.onfido-sdk-ui-Uploader-identityIcon.img-div {
  height: 70px;
  width: 100%;
  margin-bottom: 30px;
}

.gov__document-upload-description {
  font-size: 18px !important;
  line-height: 26px !important;
  font-weight: normal !important;
}
.gov__document-upload-note {
  text-align: center;
  font-size: 16px !important;
  font-weight: normal !important;
  margin-top: 4px;
}

.gov__document-upload-footer {
  margin-bottom: 40px;
  margin-top: auto;
}

.gov__document-upload-options .btn-cancel {
  background: white !important;
  color: $bluePrimary;
  border-color: white;
}

.gov__document-upload-options .btn-cancel:hover {
  border-color: white !important;
}

.gov__document-upload-options {
  margin-bottom: 40px;
  margin-top: auto;
}

.gov__document-upload-selection-container {
  display: flex;
  flex-direction: column;
}

.gov__document-upload-select-option {
  min-height: 30px;
}

.gov__document-upload-selection-container
  .gov__document-upload-select-option:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.gov__document-upload-selection-container
  .gov__document-upload-select-option:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.gov__document-upload-select-option {
  background: white;
  border: none;
  padding: 0;
  color: black;
}

input[type="file"] {
  display: none;
}

//label {
//  width: 100%;
//  height: 72px;
//  border-radius: 6px;
//  background-color: $greenPrimary;
//  border-style: solid;
//  border-color: $greenPrimary;
//  font-weight: $fontWeightExtraBold;
//  font-size: 18px;
//  cursor: pointer;
//}

.gov__document-upload-files-container {
  display: flex;
  align-items: center;
}

#gov__document-upload-remove-button {
  background: transparent;
  border: none;
  padding: 0;
  font-size: 16px;
}

.img-div {
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#gov__document-upload-files-icon {
  width: 40px;
  height: 40px;
  background-image: url("../../assets/document-combo-other.svg");
}

#gov__document-upload-checkmark {
  background-image: url("../../assets/tick.svg");
  margin-left: 2rem;
}

#gov__document-upload-file-name {
  white-space: normal;
  word-break: break-all;
  max-width: 180px;
  font-size: 0.75rem;
}

.gov__document-upload-files-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gov__label-btn {
  width: 100%;
  height: 72px;
  border-radius: 6px;
  background-color: #8dde43;
  border-style: solid;
  border-color: #8dde43;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease;
  padding-block-end: 1px;
  padding-block-start: 1px;
  padding-inline-end: 6px;
  padding-inline-start: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &.disabled {
    color: grey;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #dfdfdf;
    border-color: #dfdfdf;
    color: #787878;
    cursor: not-allowed;
  }
}

.gov__label-btn .disabled {
  color: grey;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 480px) {
  .gov__label-btn {
    width: 100%;
  }
}

.process-error p {
  text-align: center;
}

.process-error ul {
  padding-left: 120px;
}

.gov__document-input-loading {
  overflow: hidden;
  background-color: #dfdfdf !important;
  border-color: #dfdfdf !important;
  cursor: not-allowed;
  height: 72px;
}

.gov__document-input-loading .gov__loading-container {
  background: inherit;
  overflow: hidden;
}

.gov__document-input-loading .gov__loading-spinner-container > div {
  height: 100px;
  width: 100px;
}
