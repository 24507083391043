@keyframes gov__loading-spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
.gov__loading-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.8);

    .gov__loading-spinner-container {
        div {
            background-image: url('../../assets/loader.gif');
            background-size: contain;
            background-repeat: no-repeat;
            height: 200px;
            width: 200px;
        }
    }

    .gov__loading-message-container {
        padding-top: 10rem;
        position: absolute;
    }
}
