.gov__arrow-right {
    height: 34px;
    width: 37px;
    background-image: url('../../assets/arrow_white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
}

.gov__document-combo-preferred {
    height: 25px;
    width: 25px;
    background-image: url('../../assets/document-combo-preferred.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.gov__document-combo-permanent {
    height: 18px;
    width: 18px;
    background-image: url('../../assets/rubber-stamp.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.gov__document-combo-other {
    height: 33px;
    width: 33px;
    background-image: url('../../assets/document-combo-other.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.gov__chevron {
    background-image: url('../../assets/chevron.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 23px;
    height: 15px;
    cursor: pointer;

    &.right {
        transform: rotate(90deg);
    }

    &.down {
        transform: rotate(180deg);
    }

    &.left {
        transform: rotate(270deg);
    }

    &.help-section {
        width: 15px;
        margin-top: 3px;
        margin-left: 6px;
    }
}

.gov__tick {
    background-image: url('../../assets/tick.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
}

.gov__help-icon {
    background-image: url('../../assets/help-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
    cursor: pointer;
    float: right;
    margin-top: 8px;
}

.gov__lightbulb {
    background-image: url('../../assets/lightbulb.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 42px;
    width: 42px;
    margin-top: -6px;
    margin-left: -14px;
}

.gov__help-icon-activated {
    background-image: url('../../assets/help-icon-activated.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
    cursor: pointer;
}

.gov__warning-modal-icon {
    height: 300px;
    width: 300px;
    margin: 0 auto;
}

.gov__faq-profile-icon {
    background-image: url('../../assets/profile_icon.svg');
    height: 45px;
    width: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.gov__faq-document-icon {
    background-image: url('../../assets/folder_icon.svg');
    height: 41px;
    width: 41px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.gov__faq-next-steps-icon {
    background-image: url('../../assets/mail_icon.svg');
    height: 34px;
    width: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.gov__faq-system-queries {
    background-image: url('../../assets/the-server.svg');
    height: 34px;
    width: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 12px;
    margin-left: 3px;
}

@media (max-width: 374px) {
    .gov__warning-modal-icon {
        height: 200px;
        width: 200px;
    }   
}
