.gov__container {
    margin: 0 auto; // center the content of the container div
    width: 85%; // sets a width on the sides
    height: 92%;
}

// Desktop / Tablet Adjustments
@media (min-width: 768px) {
    .gov__container {
        width: 70%;
    }   
}

.gov__step-title {
    font-weight: $fontWeightExtraBold;
    letter-spacing: 0.16px;
    font-size: 16px;
    line-height: 20px;
    color: $greenPrimary;
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
}

a {
    text-decoration: none;
}

.gov__link {
    border-bottom: 2px solid $greenPrimary;
    color: $greenPrimary;
    font-size: 15px;
    font-weight: $fontWeightExtraBold;
    letter-spacing: 0.15px;
    cursor: pointer;
    white-space: nowrap;
}

.gov__form-error {
    font-weight: $fontWeightExtraBold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.03px;
    color: $red;
}

.gov__text {
    font-weight: $fontWeightExtraBold;
    line-height: 22px;
    letter-spacing: 0.03px;
  
    &.md {
        font-size: 3vh;
        line-height: 4vh;
        letter-spacing: -1.07px;
    }
    
    &.xs {
        font-size: 2vh;
        line-height: 18px;
    }

    &.xxs {
        font-size: 1.5vh;
        line-height: 16px;
    }

    // Font Weight
    &.light {
        font-weight: $fontWeightLight;
        letter-spacing: 0.02px;
    }

    // Font Colour
    &.green {
        color: $greenPrimary;
    }

    &.grey-regular {
        color: $grey;
    }

    &.grey {
        color: $greyDark;
    }
}
