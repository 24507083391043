// ******************************
// ****** LANDING PAGE **********
// ******************************
.gov__landing-page-container {
    height: 100%;
    display: flex; // displays the animation and text next to each other
    flex-direction: column; // On smaller devices, show the text vertically
    justify-content: space-evenly;
}

@media screen and (min-width: 1024px) {
    .gov__landing-page-container {
        align-items: center; // vertically align the container
        flex-direction: row-reverse; // switch the animation and text order
    }
}

.gov__landing-page-icon {
    height: 195px;
    width: 263px;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(200%);

    animation: slideInUp 1s forwards;
    -webkit-animation: slideInUp 1s forwards;
    -moz-animation: slideInUp 1s forwards;
}

@media screen and (min-width: 768px) {
    .gov__landing-page-icon {
        height: 400px;
        width: 600px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1024px) {
    .gov__landing-page-icon {
        margin-top: -5rem; // align the animation with the right to work text
    }
}

.gov__landing-page-information {
    & h1 {
        font-size: 45px;
        line-height: 50px;
        letter-spacing: -1.93px;
        margin: 0;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
        animation-delay: 1s;

        .gov__rtw-message {
            font-weight: $fontWeightLight;
            white-space: nowrap;
        }
    }

    .gov__desktop-subtext {
        padding-bottom: 1rem;
    }

    & p {
        font-size: 18px;
        line-height: 26px;
        font-weight: $fontWeightLight;
        letter-spacing: 0.02px;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
        animation-delay: 1.5s;

        span {
            font-weight: $fontWeightExtraBold;
        }
    }
}

@media screen and (min-width: 1024px) {
    .gov__landing-page-information {
        width: 40%;

        & p {
            span {
                font-weight: $fontWeightExtraBold;
            }
        }
    }
}

.gov__landing-page-buttons {
    margin-bottom: 0.5rem;
    opacity: 0;
    transform: translateY(200%);

    animation: slideInUp 1s forwards;
    -webkit-animation: slideInUp 1s forwards;
    -moz-animation: slideInUp 1s forwards;
    animation-delay: 2s;

    div {
        margin-bottom: 1rem;
    }
}
.gov__report{
    display: flex;
    align-items: baseline;
}
@media screen and (min-width: 1024px) {
    .gov__landing-page-buttons {
        text-align: left;
    }
}

// ******************************
// ********* QR CODE ************
// ******************************
.gov__qr-screen {
    height: 100%;
    width: 60%;
    margin: 4rem auto;

    div {
        text-align: center;
    }
}

.gov__qr-container {
    h1 {
        font-size: 45px;
        line-height: 50px;
        letter-spacing: -1.93px;
        margin: 0;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
    }

    p {
        font-weight: $fontWeightExtraBold;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.02px;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
        animation-delay: 0.5s;
    }

    & canvas {
        margin: 2rem 0;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
        animation-delay: 1s;
    }
}

.gov__qr-subtext-container {
    width: 60%;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(200%);

    animation: slideInUp 1s forwards;
    -webkit-animation: slideInUp 1s forwards;
    -moz-animation: slideInUp 1s forwards;
    animation-delay: 1.5s;

    p {
        text-align: left;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.02px;

        span {
            font-weight: $fontWeightExtraBold;
        }
    }
}

// ******************************
// *** INCORRECT INFORMATION ****
// ******************************
.gov__incorrect-information-container {
    margin: auto; // center the content of the container div
    width: 80%; // sets a width on the sides
    height: 92%;

    .gov__btn {
        .gov__arrow-right {
            transform: rotate(180deg) !important;
            margin-bottom: 0;
        }
    }


    .gov__incorrect-information-subtitle {
        color: $greenPrimary;
        font-weight: $fontWeightExtraBold;
        letter-spacing: 0.03px;
        font-size: 20px;
        line-height: 24px;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
    }

    .gov__incorrect-information-select-option {
        color: $black;
        font-weight: $fontWeightExtraBold;
        letter-spacing: 0.03px;
        font-size: 17.5px;
        line-height: 24px;
        opacity: 0;
    }

    .gov__incorrect-information-options {
        margin-bottom: 10px;
        .gov__gov__incorrect-information-option {
            margin-bottom: inherit;
            overflow: hidden;
            .gov__checkbox-container {
                margin-bottom: inherit;
                float:left;
            }

            .gov__option-text {
                margin-bottom: inherit;
                overflow: hidden;
            }
        }
    }

    p {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -1.07px;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
        animation-delay: 0.5s;
    }

    div {
        margin-bottom: 25px;
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
        animation-delay: 1s;
    }

    button {
        opacity: 0;
        transform: translateY(200%);

        animation: slideInUp 1s forwards;
        -webkit-animation: slideInUp 1s forwards;
        -moz-animation: slideInUp 1s forwards;
        animation-delay: 1.5s;  
    }
}

// Tablet adjustments
@media (min-width: 768px) {
    .gov__incorrect-information-container {
        width: 50%;
    }
}
