.gov__help-section-title {
    font-size: 35px;
    line-height: 40px;
    letter-spacing: -1px;
    margin: 0.5rem 0;
}

.gov__help-search {
    height: 65px;
    width: 100%;
    font-size: 22px;
    line-height: 56px;
    letter-spacing: -1.07px;
    font-weight: $fontWeightExtraBold;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.08);
    padding: 0 5px;
    background: url('../../assets/search.svg') no-repeat scroll 0px 8px;
    padding-left: 50px;
    margin-top: 25px;
    margin-bottom: 15px;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;

    &::placeholder {
        color: #767676;
        font-size: 22px;
    }
  
    &:focus {
        outline: 0;

        &::placeholder {
            color: transparent;
        }
    }

    &.error {
        border-bottom: 3px solid $red;
    }
}

.gov__search-filter {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

.gov__article-list {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

.gov__topic-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: $fontWeightExtraBold;
    letter-spacing: 0.14px;
    color: $greenPrimary;
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
    animation-delay: 0.5s;
}

.gov__popular-article {
    display: flex;
    margin-bottom: 1rem;
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
    animation-delay: 1s;

    h4 {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.5px;
        margin: 0 0 0.5rem 0;
    }

    p {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
    }
}

.gov__help-section-read-more {

    display: flex;
    align-items: center;
    margin: 1rem 0 2rem 0;

    .gov__link {
        font-size: 13px;
    }
}

.gov__faq-container {
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
    animation-delay: 1.5s;
}

.gov__faq-topic {
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.08);
    padding: 0.5rem 1rem;
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
    animation-delay: 0.75s;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 86px;
    margin-bottom: .5rem;
    cursor: pointer;
    transition: all ease-in 0.25s;

    &:hover {
        border: 3px solid $greenPrimary;
    }
}

.gov__faq-icon {
    display: flex;
    align-items: center;

    h4 {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.5px;
    }
}

.gov__filtered-by {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(151,151,151,0.19);
    padding-bottom: 1rem;

    span {
        font-size: 18px;
        line-height: 26px;
    }
}

.gov__selected-article {
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;

    h1 {
        font-size: 25px;
        line-height: 35px;
        letter-spacing: -1px;
        margin: 0;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }
}

.gov__need-to-speak {
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
    animation-delay: 2s;

    p:nth-of-type(1) {
        margin: 0;
        font-size: 18px;
        line-height: 49px;
        letter-spacing: 0.18px;
        font-weight: $fontWeightExtraBold;
        color: $greenPrimary;
    }

    p:nth-of-type(2) {
        margin-top: 0;
        font-size: 18px;
        line-height: 26px;
    }

    Button {
        margin-bottom: 2rem;
    }
}

@media (min-width: 768px) {
    .gov__chevron {    
        &.help-section {
            width: 23px;
            height: 15px;
            margin-top: 6px;
            margin-left: 6px;
        }
    }
}
