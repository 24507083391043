// COLOURS
$black: #202020;
$bluePrimary: #3640f5;
$grey: #dfdfdf;
$greyLight: #ededed;
$greyDark: #acacac;
$greenPrimary: #8dde43;
$greenSecondary: #a8ed68;
$red: #e53030;
$white: #ffffff;

// FONTS
$primaryFontFamily: "Open Sans", sans-serif;
$fontXl: 6vh;
$fontLg: 3.5vh;
$fontWeightLight: 300;
$fontWeightExtraBold: 800;
