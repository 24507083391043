.gov__form-group {
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;

    .gov__label {
        font-weight: $fontWeightLight;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.03px;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }

    &:nth-of-type(1) {
        animation-delay: 0.5s;
    }

    &:nth-of-type(2) {
        animation-delay: 1s;
    }

    &:nth-of-type(3) {
        animation-delay: 1.5s;
    }

    &:nth-of-type(4) {
        animation-delay: 2s;
        margin-top: 2rem;
    }
}
