.gov__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $fontLg;
    font-weight: $fontWeightExtraBold;
    padding: 0 2rem;
    height: 8%;
    opacity: 0;
    transform: translateY(-100%);
    animation: slideInDown 1s forwards;
    -webkit-animation: slideInDown 1s forwards;
    -moz-animation: slideInDown 1s forwards;
}
.deloitte_logo{
    display: flex;
    align-items: center;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
}
.logo{
    float: right;
    max-width: 100%;
    max-height: 100%;
}
.gov__logo {
    font-size: 25px;
    span:nth-child(1) {
        font-weight: $fontWeightLight;
    }

    span:nth-child(1).gov__deloitte {
        font-weight: $fontWeightExtraBold;
    }

    span:nth-child(2) {
        color: $greenPrimary;
    }
}
.gov__language-nav {
    display: flex;
    align-items: center;
    span {
        font-size: 14px;
        margin-left: 3px;
    }
}
