// Modal based off: https://www.w3schools.com/howto/howto_css_modals.asp
.gov__modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.8);
}
  
.gov__modal-header {
    display: flex;
    justify-content: space-between;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -1px;

    .gov__modal-banner {
        font-weight: $fontWeightExtraBold;
        
        span {
            color: $greenPrimary;
        }
    }

    // Close button
    .gov__modal-close {
        color: $greenPrimary;
        cursor: pointer;
    }
}

.gov__modal-container {
    background-color: #FEFEFE;
    margin: 5% auto;
    padding: 25px;
    box-shadow: 0 4px 8px 0 rgba($color: #d8d8d8, $alpha: 1), 0 6px 20px 0 rgba($color: #d8d8d8, $alpha: 1);
    width: 80%;
    overflow-y: auto;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.03px;

    .gov__modal-subtitle {
        font-size: 16px;
        line-height: 37px;
        letter-spacing: 0.03px;
        font-weight: $fontWeightExtraBold;
    }

    .gov__bold {
        font-weight: $fontWeightExtraBold;
    }

    h1 {
        font-size: 45px;
        line-height: 50px;
        letter-spacing: -1px;
        margin: 0;
    }
}

.gov__warning-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    h1 {
        font-size: 22px;
        line-height: 22px;
        letter-spacing: 0.04px;
        font-weight: $fontWeightExtraBold;
        margin-top: 0;
    }

    p {
        font-size: 22px;
        line-height: 22px;
        letter-spacing: 0.04px;
    }
}
