.gov__btn {
    width: 100%;
    height: 72px;
    border-radius: 6px;
    background-color: $greenPrimary;
    border-style: solid;
    border-color: $greenPrimary;
    font-weight: $fontWeightExtraBold;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.5s ease;
  
    &.circle {
        border-radius: 50%;
        height: 74px;
        width: 74px;
        color: $white;
  
        // Center the arrow icon
        display: flex;
        align-items: center;
        justify-content: center;
    }
  
    &:active {
        border-style: none;
    }
  
    &:disabled {
        background-color: $grey;
        border-color: $grey;
        color: #787878;
        cursor: not-allowed;
    }
  
    &:focus {
        outline: none;
    }
  
    &:hover:not([disabled]) {
        background-color: $greenSecondary;
        border-color: $greenSecondary;
    }
}

// Mobile Regular
@media screen and (max-width: 480px) {
    .gov__btn {
        width: 100%;
    }
}
