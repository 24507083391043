.gov__warning-modal-icon {
    width: 240px;
    height: 187px;
}

.gov__session-expired {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    div {
        h1 {
            font-size: 60px;
            line-height: 56px;
            letter-spacing: -2.93px;
            font-weight: $fontWeightExtraBold;
            margin-top: 0;
        }

        p {
            font-size: 22px;
            line-height: 30px;
            letter-spacing: 0.04px;
        }
    }
}
