.gov__terms-and-conditions-container {
    height: 100%;
    width: 100%;
    margin: 1rem auto auto auto;
}

.gov__terms-and-conditions-title {
    opacity: 0;
    transform: translateY(200%);

    animation: slideInUp 1s forwards;
    -webkit-animation: slideInUp 1s forwards;
    -moz-animation: slideInUp 1s forwards;

    h1 {
        font-size: 45px;
        line-height: 50px;
        letter-spacing: -1px;
        margin: 0;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        font-weight: $fontWeightLight;
        letter-spacing: -0.78px;
        padding-bottom: 1.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.46);

        span {
            font-weight: $fontWeightExtraBold;
            border-bottom: 2px solid $black;
            cursor: pointer;
        }
    }
}

.gov__terms-and-conditions-biometric-section {
    margin: 0;
    opacity: 0;
    transform: translateY(200%);

    animation: slideInUp 1s forwards;
    -webkit-animation: slideInUp 1s forwards;
    -moz-animation: slideInUp 1s forwards;
    animation-delay: 0.5s;

    .gov__biometric-title {
        font-size: 24px;
        line-height: 56px;
        letter-spacing: -1.17px;
        margin: 0;
        font-weight: $fontWeightExtraBold;
    }

    .gov__terms-and-conditions-subtext {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.78px;
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid rgba(151, 151, 151, 0.46);
    }
}

.gov__terms-and-conditions-footer {
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateY(200%);

    animation: slideInUp 1s forwards;
    -webkit-animation: slideInUp 1s forwards;
    -moz-animation: slideInUp 1s forwards;
    animation-delay: 1s;

    div {
        display: flex;
        justify-content: space-between;
        margin: 0 0 0.5rem 0;
    }

    .gov__consent-text {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.78px;
        font-weight: $fontWeightExtraBold;
        width: 75%;
    }

    Button {
        margin-bottom: 2rem;
    }
}

.gov__checkbox-container {
    display: flex;
    align-items: center;
}

// Checkmark based off: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
.gov__checkbox-label {
    position: relative;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;

    // Hide the browser's default checkbox
    input {
        display: none;

        // When the checkbox is checked, add a blue background
        &:checked ~ .gov__checkmark {
            // Show the checkmark when checked
            &:after {
                display: block;
            }
        }
    }

    // Style the checkmark
    .gov__checkmark:after {
        background: url('../../assets/tick.svg');
        background-size: contain;
        background-repeat: no-repeat;
        height: 25px;
        width: 30px;
    }
}

// The container holding the checkmark
.gov__checkmark {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #fefefe;
    border: 1px solid #979797;
    opacity: 0.49; border-radius: 
    2px; background-color: #FFFFFF; 
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.19);

    // Create the checkmark/indicator (hidden when not checked)
    &:after {
        content: "";
    }
}
