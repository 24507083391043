.gov_modal_data-do-not-match_picker_container{
    position: absolute; /* This positions the element relative to the viewport */
    top: -450px;
    left: 0;
    width: 100%;
    height: 300px;
    
    .gov_modal_data-do-not-match{
        display: flex;
        flex-direction: column;
        
        .gov_modal_data-do-not-match_title{
            overflow-x: hidden;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            padding: 0;
            margin: 5px 0;
            // border-bottom: 1px solid $grey;
        }

        .gov_modal_data-do-not-match-btn-container{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .gov__modal_btn {
                background: none repeat scroll 0 0 transparent;
                border: medium none;
          
                list-style: none outside none;
                margin: 0;
                padding: 0;
                text-align: left;
                text-decoration: none;
                text-indent: 0;
                font-weight: bold;
              }

              .gov_modal_data-do-not-match_btn_confirm{
                background-color: $greenPrimary;
                color: black;
                padding: 10px 20px;
                border-radius: 5px;
                cursor: pointer;
              }
        }

        .gov__loading-container-loading{
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(255, 255, 255, 0.8);
            // gap:10px;
            
            .gov__loading-spinner-container-loading{

                div {
                    background-image: url('../../assets/loader.gif');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-clip: border-box;
                    height: 200px;
                    width: 200px;
                    position: absolute;
                    top: -50px;
                    left:18%;
                }
            }
            
            .gov__loading-message-container{
                padding-top: 6rem;
                // position: absolute;
             

                p{
                    font-size: 16px;
                    
                }
            }
        }
    }
}