.gov__sidebar {
    position: fixed;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: $white;
    color: $black;
    box-sizing: border-box;
    padding: 1rem;
    transform: translateX(100%);
    z-index: 1;

    &.visible {
        animation: slideIn 0.5s forwards;
    }

    &.hidden {
        animation: slideOut 0.5s forwards;
    }
}

.gov__sidebar-header {
    height: 50px;
    width: 100%;
    font-size: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $greenPrimary;
}

.gov__language-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .gov__icon-and-title {
        display: flex;
        align-items: center;

        p {
            margin-left: 1rem;
            font-size: 19px;
            line-height: 26px;
            color: #3f3f3f;
        }
    }
}

.gov__language-icon {
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: #3f3f3f;
    background-position: center;

    &.es {
        background-image: url('../../../assets/language-icons/es.png');
        height: 12px;
        width: 20px;
    }

    &.de {
        background-image: url('../../../assets/language-icons/de.png');
        height: 12px;
        width: 20px;
    }

    &.en {
        background-image: url('../../../assets/language-icons/en.png');
        height: 16px;
        width: 20px;
    }

    &.nl {
        background-image: url('../../../assets/language-icons/nl.png');
        height: 14px;
        width: 21px;
    }

    &.pl {
        background-image: url('../../../assets/language-icons/pl.svg');
        height: 14px;
        width: 19px;
    }

    &.fr {
        background-image: url('../../../assets/language-icons/fr.png');
        height: 16px;
        width: 20px;
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}
