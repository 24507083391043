.gov__date-input {
  height: 50px;
  width: 100%;
  font-size: 20px;
  text-align: start;
  line-height: 26px;
  letter-spacing: 0.03px;
  font-weight: $fontWeightExtraBold;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $grey;
  box-sizing: border-box;
  transition: border-bottom ease 0.5s;

  &::placeholder {
    color: $greyLight;
  }

  &:focus {
    outline: 0;
    border-bottom: 3px solid $greenPrimary;
  }

  &.error {
    border-bottom: 3px solid $red;
  }
}

.gov_modal_date_picker_container {
  position: absolute; /* This positions the element relative to the viewport */
  top: -450px;
  left: 0;
  width: 100%;
  height: 420px;

  // z-index: 1000; /* Ensure it's above other content */

  .gov__modal-container{
    padding: 10px 25px;
  }

  .gov__modal-date-picker {
    font-size: 12px;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .gov__modal-date-picker_title {
      font-size: 18px;
      text-align: center;
      padding: 0;
      margin: 5px 0;
      border-bottom: 1px solid $grey;
    }

    .gov__modal-date-picker-container-values {
      display: flex;
      flex-direction: row;
      justify-content: center;

      align-items: center;
      gap: 20px;
      position: relative;

      .gov__modal-date-picker-input {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0;
        margin:0;
        span {
          margin: 0;
          padding: 0;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
        }
        .gov_modal_date_picker_title {
          font-size: 18px;
          text-align: center;
          padding: 0 10px;
          border-bottom: 1px solid $grey;
          font-weight: normal;
        }
        .gov__modal-date-picker-values {
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 0 10px;
          flex-direction: column;
        }
      }
    }
    .gov__modal_btn {
      background: none repeat scroll 0 0 transparent;
      border: medium none;

      list-style: none outside none;
      margin: 0;
      padding: 0;
      text-align: left;
      text-decoration: none;
      text-indent: 0;
      font-weight: bold;
    }

    .gov__modal-date-picker-btn-container{
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        margin-top: 20px;

        .gov__modal_btn_cancel {
            background-color: $grey;
            color: $black;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
        }

        .gov__modal_btn_confirm {
            background-color: $greenPrimary;
            color: black;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
        }
    }


    .gov__wheel-picker-container{
      display: flex;
      justify-content: center;
      gap:10px;
      .gov__wheel-picker-ithem{
        display: flex;
        flex-direction: column;
        align-items: center;

      }
      
      .wheel {
        overflow-y: scroll;
        height: 180px; /* Adjust based on your needs */
        width: 90px; /* Adjust based on your needs */
        border: 1px solid #ccc;
      }
      
      .wheel div {
        text-align: center;
        padding: 10px 0;
      }
      
      /* Highlight the selected option somehow */
      .wheel div.selected {
        background-color: $greenSecondary; /* Example: a light blue background */
        color: $black;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .gov__date-input {
    // BUGFIX - Stops a curve on the input field on iOS
    border-radius: 1px;
  }
}
