@import './components/App.scss';

* {
  color: $black;
  font-family: $primaryFontFamily;
}

html, body, #root {
    height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
