.gov__dynamic_prompts {
    .gov__dynamic_prompts-question {
      margin-top: 20px;
      margin-bottom: 30px;

      .gov__label {
        font-weight: $fontWeightLight;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.03px;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        border-radius: 1rem;
      }

        .gov__radio-btn {
          width: 1.5em;
          height: 1.5em;
          accent-color: $greenPrimary;
        }
    }
  
    .gov__dynamic_prompts-buttons {
      margin-top: 1.5rem;
      display: flex;
      align-items: flex-end;
      
      .radio {
        margin: 0.5rem;
        margin-bottom: 1rem;
  
        input[type="radio"] {
          position: absolute;
          opacity: 0;
  
          + .radio-label {
            &:before {
              float: left;
              content: '';
              background: $white;
              border-radius: 100%;
              border: 0.3px solid darken($white, 25%);
              display: inline-block;
              width: 1.8em;
              height: 1.8em;
              top: -0.3em;
              margin-right: 1em;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }
  
          &:checked {
            + .radio-label {
              &:before {
                background-color: $greenPrimary;
                box-shadow: inset 0 0 0 4px $white;
              }
            }
          }
  
          &:focus {
            + .radio-label {
              &:before {
                outline: none;
                border-color: $greenPrimary;
              }
            }
          }
  
          + .radio-label {
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .gov__form-group {
      margin-top: 100px;
        display: flex;
        gap: 10px;
    }
  }


.gov__checkbox-container {
    display: flex;
    align-items: center;
}

// Checkmark based off: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
.gov__checkbox-label {
    display: flex;
    margin-top: 1em;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;

    // Hide the browser's default checkbox
    input {
        display: none;

        // When the checkbox is checked, add a blue background
        &:checked ~ .gov__checkmark {
            // Show the checkmark when checked
            &:after {
                display: block;
            }
        }
    }

    // Style the checkmark
    .gov__checkmark:after {
        background: url('../../assets/tick.svg');
        background-size: contain;
        background-repeat: no-repeat;
        height: 25px;
        width: 30px;
    }
}

// The container holding the checkmark
.gov__checkmark {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #fefefe;
    border: 1px solid #979797;
    opacity: 0.49; border-radius: 
    2px; background-color: #FFFFFF; 
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.19);

    // Create the checkmark/indicator (hidden when not checked)
    &:after {
        content: "";
    }
}