.gov__contact-form {
    h1 {
        font-weight: $fontWeightExtraBold;
        letter-spacing: 0.16px;
        font-size: 16px;
        line-height: 20px;
        color: $greenPrimary;
        opacity: 0;
        text-transform: uppercase;
        transform: translateY(200%);
        animation: slideInUp 1s forwards;
    }

    .gov__contact-form-subtext {
        font-size: 18px;
        line-height: 26px;
        font-weight: $fontWeightLight;
        opacity: 0;
        transform: translateY(200%);
        animation: slideInUp 1s forwards;
        animation-delay: 0.5s;
    }

    .gov__btn {
        margin-top: 1rem;
        bottom: 0px;
        opacity: 0;
        transform: translateY(200%);
        animation: slideInUp 1s forwards;
        animation-delay: 1.5s;
    }
}

.gov__textarea {
    font-weight: bold;
    resize: none;
    border: 2px solid #e3e3e3;
    min-height: 230px;
    width: 88%;
    font-size: 17px;
    padding: 1rem;
    border-radius: 3px;
    transition: border ease 0.5s;
    -webkit-appearance: none;
    opacity: .50;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
    animation-delay: 1s;

    &:focus {
        outline: none;
        border: 2px solid $greenPrimary;
    }
}

.gov__character-count {
    font-size: small;
    position: absolute;
    margin-top: -40px;
    margin-left: 1rem;

    // stop text overlapping character count
    background: white;
    width: 70%;
    opacity: 0;
    transform: translateY(200%);
    animation: slideInUp 1s forwards;
    animation-delay: 1.35s;
}
