.gov__select-container {
    display: flex;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid $grey;
  
    & select {
        // Removes default select behaviour
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        outline: none;
        border: none;
      
        // Customise the styling
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.03px;
        font-weight: $fontWeightExtraBold;
        color: $greyLight;
        background-color: $white;
        width: 420px;
    
        &.active {
            color: $black;
        }
    }

    .gov__icon {
        transform: rotate(180deg);
        z-index: 1;
        position: absolute;
        right: 0.1rem;
        bottom: 0.9rem;
        display: block;
        padding: 0;
    }

    & div {
        margin-right: 2px;
        margin-top: 18px;
    }
}
